<template>
    <div class="error-container">
        <h1>Oops!</h1>
        <h2>Something went wrong and we couldn't process your request.</h2>
        <p>Please go back to the previous page and try again.</p>
    </div>
</template>

<script>
import { computed } from '@vue/composition-api';

//* plugins
import { env } from '@/assets/js/tooltify';
export default {
    name: 'errors-page-404',
    setup() {
        const backgroundImage = computed(() => {
            return env().BASE_URL + 'media/error/bg1.jpg';
        });

        return { backgroundImage };
    },
};
</script>

<style lang="scss" scope="">
body {
    margin: 30px 40px;
    background-color: #f6f6f6;
}

.error-container {
    height: 800px !important;
    padding: 50px 40px;
    font-family: Prompt, sans-serif;
    font-size: 14px;
}

h1 {
    margin: 0;
    font-size: 48px;
    font-weight: 400;
}

h2 {
    margin: 0;
    font-size: 26px;
    font-weight: 300;
}

a {
    color: #336699;
}

p.back-to-home {
    margin-top: 30px;
}

p.debug {
    padding: 20px 0px;
    font-family: Prompt, sans-serif;
    font-size: 14px;
}

.info {
    border: solid 1px #999;
    padding: 5px;
    background-color: #d9edf7;
}
</style>
